'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  const isTest = process.env.NEXT_PUBLIC_ENV_NAME === 'test';
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: isTest ? undefined : '/ingest', // Proxy through our web server in production
    ui_host: 'https://us.posthog.com',
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
