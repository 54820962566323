import(/* webpackMode: "eager", webpackExports: ["DatadogInit"] */ "/app/apps/web/collections/Analytics/Datadog/DatadogInit.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/collections/Analytics/Posthog/PosthogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/app/apps/web/collections/Analytics/Posthog/PosthogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VendorUserIdentify"] */ "/app/apps/web/collections/Analytics/VendorUserIdentify.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/collections/LiveUpdates/PusherListener.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/public/images/favicon_dark.png");
;
import(/* webpackMode: "eager" */ "/app/apps/web/public/images/favicon_dev.png");
;
import(/* webpackMode: "eager" */ "/app/apps/web/public/images/favicon.png");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/web/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/packages/ui/src/primitives/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/packages/ui/src/primitives/toaster.tsx");
