'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useParams } from 'next/navigation';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

export function VendorUserIdentify(): null {
  const posthog = usePostHog();

  const { user } = useUser();
  const { workspace } = useParams<{
    workspace?: string;
  }>();

  useEffect(() => {
    if (!user) {
      return;
    }

    // Datadog
    datadogRum.setUser({
      id: user.sub!,
      name: user.name!,
      email: user.email!,
      org: user.org_id,
    });
    datadogLogs.setUser({
      id: user.sub!,
      name: user.name!,
      email: user.email!,
      org: user.org_id,
    });

    // Posthog
    posthog?.identify(user.sub!, {
      email: user.email,
      first_name: user.given_name,
      last_name: user.family_name,
    });
    posthog?.group('org', user.org_id!, {
      org_id: user.org_id,
    });

    if (workspace) {
      // Datadog
      datadogRum.setUserProperty('workspace', workspace);
      datadogLogs.setUserProperty('workspace', workspace);

      // Posthog
      posthog?.group('workspace', workspace, {
        org_id: user.org_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, workspace]);

  return null;
}
