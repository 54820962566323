'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const isLocal = process.env.NEXT_PUBLIC_ENV_NAME === 'local';

if (!isLocal) {
  datadogRum.init({
    applicationId: '363ea5c4-2515-47cc-8bdd-73c4d0940a49',
    clientToken: 'pub8fe8e45b8c3a6e53baa9e01655a2c9cb',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'web-app',
    env: process.env.NEXT_PUBLIC_ENV_NAME,
    version: process.env.NEXT_PUBLIC_DD_GIT_COMMIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    telemetrySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      /https:\/\/.*\.getclarify\.ai/,
      /https:\/\/.*\.livecycle\.run/, // preview environment
    ],
    beforeSend: (ev) => {
      if (
        ev.type === 'error' &&
        ev.error.message.includes(
          'ResizeObserver loop completed with undelivered notifications',
        )
      ) {
        return false;
      }

      return true;
    },
  });

  datadogLogs.init({
    clientToken: 'pub8fe8e45b8c3a6e53baa9e01655a2c9cb',
    site: 'us5.datadoghq.com',
    service: 'web-app',
    env: process.env.NEXT_PUBLIC_ENV_NAME,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
    telemetrySampleRate: 100,
    version: process.env.NEXT_PUBLIC_DD_GIT_COMMIT_SHA,
  });
}

export function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
