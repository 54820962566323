'use client';

import { useParams } from 'next/navigation';
import Pusher from 'pusher-js';
import { useEffect } from 'react';

import { LiveUpdateData } from '@repo/shared/src/live-updates/types';

import {
  FeatureFlags,
  useFeatureFlagEnabled,
} from '@/app/hooks/useFeatureFlagEnabled';

import { emitReceivedLiveUpdate } from './events';

export function PusherListener() {
  const { workspace } = useParams<{
    workspace?: string;
  }>();

  const enableLiveUpdates = useFeatureFlagEnabled(
    FeatureFlags.EnableLiveUpdates,
  );

  useEffect(() => {
    if (!workspace || !enableLiveUpdates) {
      return;
    }

    const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY!, {
      cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER!,
      enableStats: true,
      channelAuthorization: {
        endpoint: `/proxy/workspaces/${workspace}/live-updates/authorize`,
        transport: 'ajax',
      },
    });

    const channelName = `private-${workspace}`;

    const channel = pusher.subscribe(channelName);

    const handleEvent = (data: LiveUpdateData) => {
      emitReceivedLiveUpdate(data);
    };

    channel.bind('update', handleEvent);

    return () => {
      channel.unbind('update', handleEvent);
      pusher.unsubscribe(channelName);
      pusher.disconnect();
    };
  }, [workspace, enableLiveUpdates]);

  return null;
}
