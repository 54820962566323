import {
  useActiveFeatureFlags,
  useFeatureFlagEnabled as usePosthogFeatureFlagEnabled,
} from 'posthog-js/react';

import { FeatureFlags } from '@repo/shared';

export const useFeatureFlagEnabled = (flag: FeatureFlags) => {
  return usePosthogFeatureFlagEnabled(flag);
};

export const useEnabledFeatureFlags = () => {
  return useActiveFeatureFlags();
};

export { FeatureFlags };
